import React from 'react'
import Service from '../../components/Service'
import { CRYPTOGRAPHY_PATH, SOFTWARE_SERVICES_PATH } from '../../routes'
import { otherProjectsCardsInformation } from '../otherProjectsCardsInformation'

export default class ComputerVisionServices extends React.Component {
    texts() {
        return this.props.texts
    }

    solutionsTexts() {
        return this.texts()['solutions']
    }

    otherSolutions() {
        const texts = this.solutionsTexts()

        return [
            {
                title: texts['softwareSolutionTitle'],
                content: texts['softwareSolutionContent'],
                pillNames: texts['softwareSolutionPillNames'],
                buttonTitle: texts['solutionsCardButton'],
                path: SOFTWARE_SERVICES_PATH,
            },
            {
                title: texts['cryptographySolutionTitle'],
                content: texts['cryptographySolutionContent'],
                pillNames: texts['cryptographySolutionPillNames'],
                buttonTitle: texts['solutionsCardButton'],
                path: CRYPTOGRAPHY_PATH,
            },
        ]
    }

    otherProjects() {
        const projectsCardInformation = otherProjectsCardsInformation(
            this.solutionsTexts(),
        )

        return [
            projectsCardInformation['toyota'],
            projectsCardInformation['adecoagro'],
        ]
    }

    render() {
        return (
            <Service
                businessUnitDetailClassName="detailed-cv-services-presentation-section"
                texts={this.texts()}
                otherSolutions={this.otherSolutions()}
                otherProjects={this.otherProjects()}
            />
        )
    }
}
