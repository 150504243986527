import React from 'react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'
import SolutionsCard from '../components/SolutionsCard'
import {
    COMPUTER_VISION_PATH,
    CRYPTOGRAPHY_PATH,
    SOFTWARE_SERVICES_PATH,
} from '../routes'
import * as animatedSpaceShip from '../media/animations/animated-space-ship.json'
import NavigationButton from '../components/NavigationButton'
import FeaturedProjects from '../components/FeaturedProjects'
import { otherProjectsCardsInformation } from './otherProjectsCardsInformation'

export default class Solutions extends React.Component {
    texts() {
        return this.props.texts
    }

    processAnimation() {
        return this.props.processAnimation
    }

    _renderOtherProjectsSection() {
        const solutionsTexts = this.texts()

        return (
            <FeaturedProjects
                title={solutionsTexts['solutionsProjectsTitle']}
                typeOfView="services"
                projects={this._projectsToRender(solutionsTexts)}
            />
        )
    }

    _projectsToRender(solutionsTexts) {
        const projectsCardInformation =
            otherProjectsCardsInformation(solutionsTexts)

        return [
            projectsCardInformation['buenbit'],
            projectsCardInformation['finaer'],
            projectsCardInformation['nextroll'],
            projectsCardInformation['toyota'],
            projectsCardInformation['adecoagro'],
        ]
    }

    _eryxLabsUrl() {
        return 'https://labs.eryx.co/'
    }

    render() {
        const texts = this.texts()
        return (
            <div className="solutions-section">
                <section className="solutions-introduction">
                    <div className="solutions-introduction-container">
                        <h2 className="solutions-title">
                            {texts['solutionsTitle']}
                        </h2>
                        <p className="solutions-text-gap">
                            {texts['solutionsDescription']}
                        </p>

                        <div className="solutions-card-rows">
                            <div className="solutions-first-row">
                                <SolutionsCard
                                    title={texts['softwareSolutionTitle']}
                                    content={texts['softwareSolutionContent']}
                                    pillNames={
                                        texts['softwareSolutionPillNames']
                                    }
                                    completeHeight
                                    buttonTitle={texts['solutionsCardButton']}
                                    path={SOFTWARE_SERVICES_PATH}
                                    theme="violet"
                                />
                            </div>

                            <div className="solutions-second-row">
                                <SolutionsCard
                                    title={texts['computerVisionSolutionTitle']}
                                    content={
                                        texts['computerVisionSolutionContent']
                                    }
                                    pillNames={
                                        texts['computerVisionSolutionPillNames']
                                    }
                                    completeHeight
                                    buttonTitle={texts['solutionsCardButton']}
                                    path={COMPUTER_VISION_PATH}
                                    theme="violet"
                                />

                                <SolutionsCard
                                    title={texts['cryptographySolutionTitle']}
                                    content={
                                        texts['cryptographySolutionContent']
                                    }
                                    pillNames={
                                        texts['cryptographySolutionPillNames']
                                    }
                                    completeHeight
                                    buttonTitle={texts['solutionsCardButton']}
                                    path={CRYPTOGRAPHY_PATH}
                                    theme="violet"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="solutions-labs">
                    <div className="solutions-labs-content-container">
                        <h2 className="labs-title">
                            {texts['solutionsLabsTitle']}
                        </h2>
                        <p className="labs-description">
                            {texts['solutionsLabsContent']}
                        </p>
                        <NavigationButton
                            openInNewTab
                            title={texts['solutionsLabsButton']}
                            arrowDirection="right"
                            url={this._eryxLabsUrl()}
                        />
                    </div>
                    <div className="solutions-labs-ship-container">
                        <Lottie
                            options={{ animationData: animatedSpaceShip }}
                            style={{
                                transform: 'rotate(-15deg)',
                            }}
                        />
                    </div>
                </section>

                <section className="solutions-our-process">
                    <div className="solutions-our-process-container">
                        <div className="solutions-our-process-header">
                            <h2>{texts['solutionsProcessTitle']}</h2>
                        </div>

                        <div>
                            <Lottie
                                options={{
                                    animationData: this.processAnimation(),
                                }}
                                style={{
                                    height: '600px',
                                }}
                            />
                        </div>
                    </div>
                </section>

                <section className="solutions-projects">
                    {this._renderOtherProjectsSection()}
                </section>
            </div>
        )
    }
}

Solutions.propTypes = {
    texts: PropTypes.any.isRequired,
    processAnimation: PropTypes.any.isRequired,
}
