import React from 'react'
import Project from '../../components/Project'
import { COMPUTER_VISION_PATH } from '../../routes'
import { otherProjectsCardsInformation } from '../otherProjectsCardsInformation'

export default class ToyotaProject extends React.Component {
    texts() {
        return this.props.texts
    }

    image() {
        return this.props.image
    }

    solutionsTexts() {
        return this.texts()['solutions']
    }

    otherProjects() {
        const projectsCardInformation = otherProjectsCardsInformation(
            this.solutionsTexts(),
        )

        return [
            projectsCardInformation['adecoagro'],
            projectsCardInformation['finaer'],
            projectsCardInformation['nextroll'],
        ]
    }

    render() {
        return (
            <Project
                texts={this.texts()}
                projectImg={this.image()}
                otherProjects={this.otherProjects()}
                projectAreaPath={COMPUTER_VISION_PATH}
            />
        )
    }
}
