export const HOME_PATH = '/'
export const CONTACT_PATH = '/contacto'
export const SOLUTIONS_PATH = '/soluciones'
export const BUENBIT_PATH = '/soluciones/buenbit'
export const FINAER_PATH = '/soluciones/finaer'
export const NEXT_ROLL_PATH = '/soluciones/next-roll'
export const TOYOTA_PATH = '/soluciones/toyota'
export const ADECOAGRO_PATH = '/soluciones/adecoagro'
export const US_PATH = '/somos'
export const SOFTWARE_SERVICES_PATH = '/servicios/software-a-medida'
export const COMPUTER_VISION_PATH = '/servicios/computer-vision'
export const CRYPTOGRAPHY_PATH = '/servicios/criptografia'

