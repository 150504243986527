import React from 'react'
import Project from '../../components/Project'
import buenbitDesktopImage from '../../media/clients/buenbit-desktop-project.webp'
import { SOFTWARE_SERVICES_PATH } from '../../routes'
import { otherProjectsCardsInformation } from '../otherProjectsCardsInformation'

export default class BuenbitProject extends React.Component {
    texts() {
        return this.props.texts
    }

    image() {
        return this.props.image
    }

    solutionsTexts() {
        return this.texts()['solutions']
    }

    otherProjects() {
        const projectsCardInformation = otherProjectsCardsInformation(
            this.solutionsTexts(),
        )

        return [
            projectsCardInformation['finaer'],
            projectsCardInformation['nextroll'],
            projectsCardInformation['toyota'],
        ]
    }

    render() {
        return (
            <Project
                texts={this.texts()}
                image={this.image()}
                projectImg={buenbitDesktopImage}
                otherProjects={this.otherProjects()}
                projectAreaPath={SOFTWARE_SERVICES_PATH}
            />
        )
    }
}
