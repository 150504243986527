import React from 'react'
import Navbar from '../components/Navbar'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import Footer from '../components/Footer'
import NotFound from './NotFound'
import TextsTranslations from './textsTranslations.json'
import Us from './Us'
import Solutions from './Solutions'
import ContactUs from './ContactUs'
import {
    ADECOAGRO_PATH,
    BUENBIT_PATH,
    COMPUTER_VISION_PATH,
    CONTACT_PATH,
    CRYPTOGRAPHY_PATH,
    FINAER_PATH,
    HOME_PATH,
    NEXT_ROLL_PATH,
    SOFTWARE_SERVICES_PATH,
    SOLUTIONS_PATH,
    TOYOTA_PATH,
    US_PATH,
} from '../routes'
import SoftwareServices from './Services/SoftwareServices'
import BuenbitProject from './Projects/BuenbitProject'
import CryptographyServices from './Services/CryptographyServices'
import ComputerVisionServices from './Services/ComputerVisionServices'
import FinaerProject from './Projects/FinaerProject'
import NextRollProject from './Projects/NextRollProject'
import partnersSpanishData from './PartnersSpanishData.json'
import partnersEnglishData from './PartnersEnglishData.json'
import * as animatedProcess from '../media/animations/animated-process.json'
import * as animatedENProcess from '../media/animations/animated-en-process.json'
import * as animatedProcessMobile from '../media/animations/animated-process-mobile.json'
import * as animatedENProcessMobile from '../media/animations/animated-en-process-mobile.json'
import * as animatedDreamTeam from '../media/animations/animated-dream-team.json'
import * as animatedENDreamTeam from '../media/animations/animated-en-dream-team.json'
import * as animatedDreamTeamMobile from '../media/animations/animated-dream-team-mobile.json'
import * as animatedENDreamTeamMobile from '../media/animations/animated-en-dream-team-mobile.json'
import ToyotaProject from './Projects/ToyotaProject'
import toyotaProjectSpanishImage from '../media/clients/toyota-desktop-project-es.webp'
import toyotaProjectEnglishImage from '../media/clients/toyota-desktop-project-en.webp'
import buenbitProjectImage from '../media/clients/buenbit-desktop-project.webp'
import finaerProjectImage from '../media/clients/finaer-desktop-project.webp'
import nextrollProjectImage from '../media/clients/logo-nextroll.webp'
import adecoagroProjectImage from '../media/clients/adecoagro-project-card.webp'
import AdecoagroProject from './Projects/AdecoagroProject'

export default class App extends React.Component {
    LANGUAGES = {
        ES: 'ES',
        EN: 'EN',
    }

    constructor(props) {
        super(props)
        this.state = {
            language: this.LANGUAGES.ES,
            isMobile: window.innerWidth <= 768,
        }
    }

    language() {
        return this.state.language
    }

    handleLanguageChange(language) {
        this.setState({ language })
    }

    textsFor(view) {
        const selectedLanguage = this.language()
        return TextsTranslations[selectedLanguage][view]
    }

    homeTexts() {
        return this.textsFor('home')
    }

    footerTexts() {
        return this.textsFor('footer')
    }

    appTexts() {
        return this.textsFor('app')
    }

    contactTexts() {
        return this.textsFor('contact')
    }

    solutionsTexts() {
        return this.textsFor('solutions')
    }

    isMobile() {
        return this.state.isMobile
    }

    solutionsAnimation() {
        const isMobile = this.isMobile()
        if (this.language() === this.LANGUAGES.ES) {
            return isMobile ? animatedProcessMobile : animatedProcess
        }
        return isMobile ? animatedENProcessMobile : animatedENProcess
    }

    dreamTeamAnimation() {
        const isMobile = window.innerWidth <= 1200
        if (this.language() === this.LANGUAGES.ES) {
            return isMobile ? animatedDreamTeamMobile : animatedDreamTeam
        }
        return isMobile ? animatedENDreamTeamMobile : animatedENDreamTeam
    }

    usTexts() {
        return this.textsFor('us')
    }

    projectsTexts() {
        return this.textsFor('projects')
    }

    servicesTexts() {
        return this.textsFor('services')
    }

    solutionText(solution) {
        const servicesTexts = this.servicesTexts()
        return {
            ...servicesTexts[solution],
            otherSolutionsTitle: servicesTexts['otherSolutionsTitle'],
            highlightedProjectsTitle: servicesTexts['highlightedProjectsTitle'],
            solutions: this.solutionsTexts(),
        }
    }

    softwareServicesTexts() {
        return this.solutionText('software')
    }

    computerVisionTexts() {
        return this.solutionText('computerVision')
    }

    cryptographyTexts() {
        return this.solutionText('cryptography')
    }

    notFoundTexts() {
        return this.textsFor('error')
    }

    serviceText(project) {
        const projectTexts = this.projectsTexts()
        return {
            ...this.servicesTexts()['software'],
            otherProjectsTitle: projectTexts['otherProjectsTitle'],
            solutions: this.solutionsTexts(),
            project: projectTexts[project],
        }
    }

    buenbitTexts() {
        return this.serviceText('buenbit')
    }

    finaerTexts() {
        return this.serviceText('finaer')
    }

    nextRollTexts() {
        return this.serviceText('nextRoll')
    }

    toyotaTexts() {
        return this.serviceText('toyota')
    }

    adecoagroTexts() {
        return this.serviceText('adecoagro')
    }

    toyotaImage() {
        if (this.language() === this.LANGUAGES.ES) {
            return toyotaProjectSpanishImage
        } else {
            return toyotaProjectEnglishImage
        }
    }

    partners() {
        if (this.language() === this.LANGUAGES.ES) {
            return partnersSpanishData
        } else {
            return partnersEnglishData
        }
    }

    render() {
        return (
            <>
                <Navbar
                    onLanguageChange={(language) =>
                        this.handleLanguageChange(language)
                    }
                    texts={this.appTexts()}
                />
                <Routes>
                    <Route
                        path={HOME_PATH}
                        element={
                            <Home
                                texts={this.homeTexts()}
                                dreamTeamAnimation={this.dreamTeamAnimation()}
                            />
                        }
                    />

                    <Route
                        path={US_PATH}
                        element={
                            <Us
                                texts={this.usTexts()}
                                partners={this.partners()}
                            />
                        }
                    />

                    <Route
                        path={SOLUTIONS_PATH}
                        element={
                            <Solutions
                                texts={this.solutionsTexts()}
                                processAnimation={this.solutionsAnimation()}
                            />
                        }
                    />

                    <Route
                        path={CONTACT_PATH}
                        element={<ContactUs texts={this.contactTexts()} />}
                    />

                    <Route
                        path={SOFTWARE_SERVICES_PATH}
                        element={
                            <SoftwareServices
                                texts={this.softwareServicesTexts()}
                            />
                        }
                    />

                    <Route
                        path={COMPUTER_VISION_PATH}
                        element={
                            <ComputerVisionServices
                                texts={this.computerVisionTexts()}
                            />
                        }
                    />

                    <Route
                        path={CRYPTOGRAPHY_PATH}
                        element={
                            <CryptographyServices
                                texts={this.cryptographyTexts()}
                            />
                        }
                    />

                    <Route
                        path={BUENBIT_PATH}
                        element={
                            <BuenbitProject
                                texts={this.buenbitTexts()}
                                image={buenbitProjectImage}
                            />
                        }
                    />

                    <Route
                        path={FINAER_PATH}
                        element={
                            <FinaerProject
                                texts={this.finaerTexts()}
                                image={finaerProjectImage}
                            />
                        }
                    />

                    <Route
                        path={NEXT_ROLL_PATH}
                        element={
                            <NextRollProject
                                texts={this.nextRollTexts()}
                                image={nextrollProjectImage}
                            />
                        }
                    />

                    <Route
                        path={TOYOTA_PATH}
                        element={
                            <ToyotaProject
                                texts={this.toyotaTexts()}
                                image={this.toyotaImage()}
                            />
                        }
                    />

                    <Route
                        path={ADECOAGRO_PATH}
                        element={
                            <AdecoagroProject
                                texts={this.adecoagroTexts()}
                                image={adecoagroProjectImage}
                            />
                        }
                    />

                    <Route
                        path="*"
                        element={<NotFound texts={this.notFoundTexts()} />}
                    />
                </Routes>
                <Footer texts={this.footerTexts()}></Footer>
            </>
        )
    }
}
