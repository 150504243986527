import {
    ADECOAGRO_PATH,
    BUENBIT_PATH,
    FINAER_PATH,
    NEXT_ROLL_PATH,
    TOYOTA_PATH,
} from '../routes'
import adecoagroProjectCardImage from '../media/clients/adecoagro-project-card.webp'
import finaerProjectCardImage from '../media/clients/finaer-project-card-image.webp'
import buenbitProjectCardImage from '../media/clients/buenbit-project-card-image.webp'
import nextrollProjectCardImage from '../media/clients/next-roll-project-card-image.webp'
import toyotaProjectCardImage from '../media/clients/toyota-project-card-image.webp'

export function otherProjectsCardsInformation(solutionsTexts) {
    return {
        finaer: {
            title: solutionsTexts['solutionsFinaerProjectTitle'],
            content: solutionsTexts['solutionsFinaerProjectContent'],
            image: finaerProjectCardImage,
            path: FINAER_PATH,
            button: solutionsTexts['solutionsCardButton'],
        },
        adecoagro: {
            title: solutionsTexts['solutionsAdecoagroProjectTitle'],
            content: solutionsTexts['solutionsAdecoagroProjectContent'],
            image: adecoagroProjectCardImage,
            path: ADECOAGRO_PATH,
            button: solutionsTexts['solutionsCardButton'],
        },
        toyota: {
            image: toyotaProjectCardImage,
            title: solutionsTexts['solutionsToyotaProjectTitle'],
            content: solutionsTexts['solutionsToyotaProjectContent'],
            path: TOYOTA_PATH,
            button: solutionsTexts['solutionsProjectButton'],
        },
        nextroll: {
            title: solutionsTexts['solutionsNextRollProjectTitle'],
            content: solutionsTexts['solutionsNextRollProjectContent'],
            image: nextrollProjectCardImage,
            path: NEXT_ROLL_PATH,
            button: solutionsTexts['solutionsCardButton'],
        },
        buenbit: {
            title: solutionsTexts['solutionsBuenbitProjectTitle'],
            content: solutionsTexts['solutionsBuenbitProjectContent'],
            image: buenbitProjectCardImage,
            path: BUENBIT_PATH,
            button: solutionsTexts['solutionsCardButton'],
        },
    }
}
